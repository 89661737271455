import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getAuth } from "firebase/auth";
import { db } from '../../config/firebaseConfig';
import { getDocs, collection, deleteDoc, doc, query, where } from 'firebase/firestore';
import { Button } from '@mui/material';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import PulsedEvent from './PulsedEvent'; // Import the PulsedEvent component
import './PulsedCalendar.css';

const PulsedCalendar = ({ userData }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const localizer = momentLocalizer(moment);
  const [activeView, setActiveView] = useState('month');
  const [isStudyPlanPanelOpen, setIsStudyPlanPanelOpen] = useState(false);
  const [selectedStudyPlan, setSelectedStudyPlan] = useState(null);
  const [events, setEvents] = useState([]);

  // Update the locale to start the week from Monday
  useEffect(() => {
    moment.updateLocale('en', {
      week: {
        dow: 1,
        doy: 1,
      },
    });
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      if (user) {
        const q = query(collection(db, 'events'), where('attendees', 'array-contains', user.uid));
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          title: doc.data().title,
          note: doc.data().note,
          start: new Date(doc.data().startTime),
          end: new Date(doc.data().endTime),
          subject: doc.data().subject,
          attendees: doc.data().attendees,
        }));
        setEvents(eventsList);
      }
    };

    fetchEvents();
  }, [user]);

  const handleNavigate = (date, view) => {
    console.log(view);
    if (view === 'day') {
      console.log('Navigating to day:', date);
    }
  };

  const onView = (view) => {
    console.log('Switching to view:', view);
    setActiveView(view);
  };

  const handleSelectSlot = ({ start, end }) => {
    const newCalendarEvent = {
      start: moment(start).format('YYYY-MM-DDTHH:mm'),
      end: moment(end).format('YYYY-MM-DDTHH:mm'),
    };
    openStudyPlanPanel(newCalendarEvent);
  };

  const handleSelectEvent = (event) => {
    console.log(event);
    openStudyPlanPanel(event);
  };

  const openStudyPlanPanel = (studyPlan) => {
    setIsStudyPlanPanelOpen(true);
    setSelectedStudyPlan(studyPlan);
  };

  const closeStudyPlanPanel = () => {
    setIsStudyPlanPanelOpen(false);
    setSelectedStudyPlan(null);
  };

  const handleSave = (formData) => {
    const newEvent = {
      id: formData.id,
      title: formData.title,
      note: formData.note,
      start: new Date(formData.startTime),
      end: new Date(formData.endTime),
      subject: formData.subject,
      attendees: formData.attendees,
    };
    setEvents((prevEvents) => [...prevEvents, newEvent]);
    closeStudyPlanPanel();
  };

  const handleDelete = async (event) => {
    try {
      await deleteDoc(doc(db, 'events', event.id));
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const eventPropGetter = (event) => {
    return {
      style: {
        backgroundColor: '#3174ad',
        color: 'white',
        borderRadius: '5px',
        display: 'block',
        position: 'relative'
      }
    };
  };

  const Event = ({ event }) => (
    <span>
      <strong>{event.title}</strong>
      <p>{event.note}</p>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => handleDelete(event)}
        style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 2 }}
      >
        Delete
      </Button>
    </span>
  );

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        defaultView="month"
        onView={onView}
        onNavigate={handleNavigate}
        onSelectSlot={handleSelectSlot}
        selectable
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventPropGetter}
        components={{
          event: Event
        }}
      />
      {isStudyPlanPanelOpen && (
        <PulsedEvent
          eventDetails={selectedStudyPlan}
          onClose={closeStudyPlanPanel}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default PulsedCalendar;
