import React, { useEffect, useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../config/firebaseConfig'; // Adjust this import according to where you initialize Firebase
import './AvatarUpload.css';

const AvatarUpload = ({ user }) => {
    const [avatarUrl, setAvatarUrl] = useState('');

    useEffect(() => {
        // Function to fetch the avatar URL
        const fetchAvatarUrl = async () => {
            const avatarRef = ref(storage, `avatars/${user.uid}`);
            try {
                const url = await getDownloadURL(avatarRef);
                setAvatarUrl(url); // Set the avatar URL if it exists
            } catch (error) {
                console.log("Avatar not found, or error fetching: ", error);
                // Handle any errors or set a default avatar if needed
            }
        };

        if (user && user.uid) {
            fetchAvatarUrl(); // Call the function if the user and user UID exist
        }
    }, [user]); 

    const handleAvatarChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const avatarRef = ref(storage, `avatars/${user.uid}`);
        try {
            await uploadBytes(avatarRef, file);
            const url = await getDownloadURL(avatarRef);
            setAvatarUrl(url);
        } catch (error) {
            console.error("Error uploading avatar: ", error);
        }
    };

    return (
        <div className="avatarContainer">
            {avatarUrl && <img src={avatarUrl} alt="Avatar" />}
            <input
                id="avatarInput"
                type="file"
                onChange={handleAvatarChange}
                style={{ display: 'none' }}
            />
            <label htmlFor="avatarInput" className="uploadButton">
                Upload Avatar
            </label>
        </div>
    );
};

export default AvatarUpload;
