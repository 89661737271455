import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import './Content.css'; // Adjust the path according to your file structure
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function FileListModal({ open, handleClose, handleDelete, files, handleDownload }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="file-list-modal-title"
            aria-describedby="file-list-modal-description"
        >
            <Box className="fileListModal">
                <Typography id="file-list-modal-title" variant="h6" component="h2">
                    Files for Download
                </Typography>
                <List dense>
                    {files.map((file) => (
                        <ListItem key={file.name} secondaryAction={
                            handleDelete ? ( // Check if handleDelete is provided
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file.filePath)}>
                                    <DeleteIcon />
                                </IconButton>
                            ) : null // If handleDelete is not provided, don't render the IconButton
                        }>
                            <ListItemButton onClick={() => handleDownload(file.url)}>
                                <ListItemText primary={file.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Modal>
    );
}

export default FileListModal;
