// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebaseConfig';
import LandingPage from './auth/LandingPage';
import PrivacyPolicy from './auth/PrivacyPolicy';
import TermsAndConditions from './auth/TermsAndConditions';
import Dashboard from './components/Dashboard/Dashboard';
// import Home from './templates/onepirate/Home';

const AppWrapper = ({ children }) => {
  const location = useLocation();
  const [backgroundClass, setBackgroundClass] = useState('');

  useEffect(() => {
    if (location.pathname.startsWith('/login')) {
      setBackgroundClass('login-background');
    } else if (location.pathname.startsWith('/privacy-policy')) {
      setBackgroundClass('privacy-policy-background');
    } else if (location.pathname.startsWith('/dashboard')) {
      setBackgroundClass('dashboard-background');
    } else {
      setBackgroundClass('');
    }
  }, [location]);

  return <div className={`App ${backgroundClass}`}>{children}</div>;
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
      setUser(user);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <AppWrapper>
        <Routes>
          <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <LandingPage />} />
          <Route path="/login/*" element={isLoggedIn ? <Navigate to="/dashboard" /> : <LandingPage />} />
          <Route path="/dashboard/*" element={isLoggedIn ? <Dashboard user={user} /> : <Navigate to="/login" />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
      </AppWrapper>
    </Router>
  );
}

export default App;
