import { Link, Typography } from '@mui/material';

const Copyright = (props) => {
  const currentYear = new Date().getFullYear();
  const websiteName = "Modus Medizin"; 
  const websiteUrl = "https://modus-medizin.de/"; 

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {/* English Version */}
      {'Copyright © '}
      <Link color="inherit" href={websiteUrl}>
        {websiteName}
      </Link>{' '}
      {currentYear}
      {'. '}
    </Typography>
  );
}

export default Copyright;
