// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#262a5e', // Primary color from the website
      contrastText: '#fff', // White text on primary color
    },
    secondary: {
      main: '#3e64de', // A secondary color, choose as per your preference
    },
    // Add other customizations as needed
  },
  typography: {
    // Define typography here if needed
  },
  // You can also customize other theme aspects like spacing, breakpoints, etc.
});

export default theme;