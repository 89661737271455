// TermsAndConditions.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Container component="main" maxWidth="md">
            <Typography variant="h2" gutterBottom>
                Terms and Conditions
            </Typography>
            <Typography variant="body1" gutterBottom>
                Your terms and conditions content goes here.
            </Typography>
        </Container>
    );
};

export default TermsAndConditions;
