// StudyConsole.js
import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebaseConfig';
import { getDocs, collection, query, where, doc, getDoc } from 'firebase/firestore';
import { Box, Card, CardActionArea, CardContent, Typography, Modal } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import StudyPlanCard from './StudyPlanCard';
import StudyPlanForm from './StudyPlanForm';
import './Study.css';

const StudyPlansConsole = ({ user }) => {
    const [showForm, setShowForm] = useState(false);
    const [studyPlans, setStudyPlans] = useState([]);
    const [studyDetails, setStudyDetails] = useState([]);

    useEffect(() => {
        const fetchStudyPlans = async () => {
            if (!user?.uid) return;

            const q = query(collection(db, "studyPlans"), where("userId", "==", user.uid));
            const studyPlansSnapshot = await getDocs(q);
            const studyPlansData = studyPlansSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setStudyPlans(studyPlansData);

            const examsData = await Promise.all(studyPlansData.map(async (plan) => {
                const examDoc = await getDoc(doc(db, "exams", plan.exam));
                if (examDoc.exists()) {
                    return { ...plan, exam: { id: examDoc.id, ...examDoc.data() } };
                } else {
                    return { ...plan, exam: null };
                }
            }));

            const examsWithTopics = await Promise.all(examsData.map(async (plan) => {
                if (plan.exam && plan.exam.topics) {
                    const topicsData = await Promise.all(plan.exam.topics.map(async (topicId) => {
                        const topicDoc = await getDoc(doc(db, "topics", topicId));
                        if (topicDoc.exists()) {
                            return { id: topicDoc.id, ...topicDoc.data() };
                        } else {
                            return null;
                        }
                    }));
                    return { ...plan, topics: topicsData.filter(topic => topic !== null) };
                } else {
                    return { ...plan, topics: [] };
                }
            }));

            const plansWithChapters = await Promise.all(examsWithTopics.map(async (plan) => {
                const topicsWithChapters = await Promise.all(plan.topics.map(async (topic) => {
                    const chapterQuery = query(collection(db, "chapters"), where("topicId", "==", topic.id));
                    const chapterSnapshot = await getDocs(chapterQuery);
                    const chapters = chapterSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    return { ...topic, chapters };
                }));
                return { ...plan, topics: topicsWithChapters };
            }));

            setStudyDetails(plansWithChapters);
        };

        fetchStudyPlans();
    }, [user?.uid]);

    return (
        <Box sx={{ margin: 2 }}>
            <Typography variant="h4" gutterBottom>Your Study Plans</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {studyDetails.length > 0 && studyDetails.map((plan) => (
                    <StudyPlanCard key={plan.id} plan={plan} user={user} setShowForm={setShowForm} />
                ))}

                <Card className="study-plan-card study-plan-new-plan" onClick={() => setShowForm(true)}>
                    <CardContent className='study-console-card-content'>
                        <AddCircleOutlineIcon fontSize="inherit" />
                    </CardContent>
                </Card>

                <Modal
                    open={showForm}
                    onClose={() => setShowForm(false)}
                    aria-labelledby="study-plan-form-modal"
                    aria-describedby="Modal to create or edit a study plan"
                >
                    <Box className="study-plan-form-box">
                        <StudyPlanForm user={user} setShowForm={setShowForm} setStudyPlans={setStudyPlans} />
                    </Box>
                </Modal>
            </Box>
        </Box>
    );
};

export default StudyPlansConsole;
