import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, deleteUser, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc, collection, getDocs } from "firebase/firestore";
import { db } from '../../config/firebaseConfig';
import AvatarUpload from './AvatarUpload';
import './Profile.css';

const Profile = ({ userData }) => {
  const [profileData, setProfileData] = useState({
    username: '',
    university: '',
    startSemester: '',
    currentSemester: '',
    emailForAction: '',
    isStudent: false
  });
  const [universities, setUniversities] = useState([]);
  const [showConfirmAction, setShowConfirmAction] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const fetchUserData = () => {
      if (user && userData && isMounted) {
        setProfileData({
          username: userData.username || '',
          university: userData.university || '',
          startSemester: userData.startSemester || '',
          currentSemester: userData.currentSemester || '',
          emailForAction: '',
          isStudent: userData.roles?.includes('student') || false
        });
      }
    };

    const fetchUniversities = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "universities"));
        if (isMounted) {
          const uniList = querySnapshot.docs.map(doc => doc.data().name);
          setUniversities(uniList);
        }
      } catch (error) {
        console.error("Failed to fetch universities:", error);
      }
    };

    fetchUserData();
    fetchUniversities();

    return () => {
      isMounted = false; // Cleanup flag to prevent state updates after unmount
    };
  }, [userData, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      const { username, university, startSemester, currentSemester } = profileData;
      try {
        await setDoc(doc(db, "users", user.uid), {
          username,
          university,
          startSemester,
          currentSemester
        }, { merge: true });
        alert("Profile updated successfully");
      } catch (error) {
        console.error("Failed to update profile:", error);
      }
    }
  };

  const initiateAction = (actionType) => {
    setShowConfirmAction(actionType); // "resetPassword" or "deleteAccount"
  };

  const confirmAction = async () => {
    if (profileData.emailForAction === user.email) {
      try {
        if (showConfirmAction === "resetPassword") {
          await sendPasswordResetEmail(auth, user.email);
          alert("Password reset email sent.");
        } else if (showConfirmAction === "deleteAccount") {
          await deleteUser(user);
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to perform action:", error);
      }
    } else {
      alert("Email does not match the logged-in user.");
    }
    setShowConfirmAction(''); // Reset confirmation state
  };

  const { username, university, startSemester, currentSemester, emailForAction, isStudent } = profileData;

  return (
    <form className="profileForm" onSubmit={handleSubmit}>
      <AvatarUpload user={user} />
      <div className="formGroup">
        <label htmlFor="username">Username:</label>
        <input
          id="username"
          name="username"
          type="text"
          value={username}
          onChange={handleChange}
        />
      </div>

      {isStudent && (
        <>
          <div className="formGroup">
            <label htmlFor="startSemester">Start Semester:</label>
            <input
              id="startSemester"
              name="startSemester"
              type="text"
              value={startSemester}
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label htmlFor="currentSemester">Current Semester:</label>
            <input
              id="currentSemester"
              name="currentSemester"
              type="text"
              value={currentSemester}
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label htmlFor="university">University:</label>
            <select
              id="university"
              name="university"
              value={university}
              onChange={handleChange}
            >
              <option value="">Select University</option>
              {universities.map((uni, index) => (
                <option key={index} value={uni}>{uni}</option>
              ))}
            </select>
          </div>
        </>
      )}

      <div className="formActions">
        <button type="submit">Save Profile</button>
        {showConfirmAction && (
          <>
            <input
              type="email"
              name="emailForAction"
              placeholder="Confirm your email"
              value={emailForAction}
              onChange={handleChange}
            />
            <button className={showConfirmAction} type="button" onClick={confirmAction}>Confirm {showConfirmAction}</button>
          </>
        )}
        {!showConfirmAction && (
          <>
            <button className="resetPassword" type="button" onClick={() => initiateAction('resetPassword')}>Reset Password</button>
            <button className="deleteAccount" type="button" onClick={() => initiateAction('deleteAccount')}>Delete Account</button>
          </>
        )}
      </div>
    </form>
  );
};

export default Profile;
