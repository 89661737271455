// LandingPage.js
import React, { useState } from 'react';
import { Container, Button, Box, Card, CardContent, Typography, Modal } from '@mui/material';
import AuthForm from './AuthForm';
import ConsentBanner from './ConsentBanner';

const LandingPage = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Container component="main" maxWidth="md" className='landing-container'>
            <Box className="landing-container">
                <Typography variant="h2" className="landing-title">
                    Pulse Project
                </Typography>
                <Typography variant="h5" className="landing-subtitle">
                    Your exam planner and study tracker
                </Typography>
                <Button variant="contained" color="primary" onClick={handleOpen} className="landing-button">
                    Get Started
                </Button>
                <Modal open={open} onClose={handleClose}>
                    <Card className="modal-card">
                        <CardContent className="modal-card-content">
                            <AuthForm />
                        </CardContent>
                    </Card>
                </Modal>
            </Box>
            <ConsentBanner />
        </Container>
    );
};

export default LandingPage;
