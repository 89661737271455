// Dashboard.js
import React, { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../config/firebaseConfig';
import { Route, NavLink, Routes, useNavigate } from 'react-router-dom';
import Profile from '../Profile/Profile';
import ContentTree from '../Content/Content';
import PulsedCalendar from '../Calendar/PulsedCalendar';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Container,
  ThemeProvider,
  createTheme,
  Toolbar
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import CategoryIcon from '@mui/icons-material/Category';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import StudyPlansConsole from '../Study/StudyConsole';
import './Dashboard.css';

const theme = createTheme();

const Dashboard = ({ user }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (!user || !user.uid) {
      console.log("User is not authenticated or user ID is missing");
      return;
    }

    const fetchUserData = async () => {
      const userDocRef = doc(db, "users", user.uid);
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.log(`No such user document for user ID: ${user.uid}`);
        }
      } catch (error) {
        console.error(`Error fetching user data for user ID: ${user.uid}`, error);
      }
    };

    fetchUserData();

    return () => {
      // Any necessary cleanup can go here
    };
  }, [user]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='dashboard-container'>
        <Drawer
          variant="permanent"
          className="drawer"
        >
          <Toolbar />
          <List>
            <ListItemButton component={NavLink} to="/dashboard/profile">
              <ListItemIcon>
                <SettingsSuggestIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
            <ListItemButton component={NavLink} to="/dashboard/content">
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Content" />
            </ListItemButton>
            <ListItemButton component={NavLink} to="/dashboard/studyConsole">
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Plan" />
            </ListItemButton>
            <ListItemButton component={NavLink} to="/dashboard/calendar">
              <ListItemIcon>
                <CalendarTodayIcon />
              </ListItemIcon>
              <ListItemText primary="Calendar" />
            </ListItemButton>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </Drawer>
        <Container className='dashboard-content'>
          <Routes>
            <Route path="/profile" element={<Profile userData={userData} />} />
            <Route path="/content" element={<ContentTree userData={userData} />} />
            <Route path="/studyConsole" element={<StudyPlansConsole user={user} />} />
            <Route path="/calendar" element={<PulsedCalendar userData={userData} />} />
          </Routes>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default Dashboard;
