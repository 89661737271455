import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { db } from '../../config/firebaseConfig';
import { getDocs, collection, addDoc } from 'firebase/firestore';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import './PulsedEvent.css';

const PulsedEvent = ({ eventDetails, onClose, onSave }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [attendees, setAttendees] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    note: '',
    attendee: [],
    subject: '',
    startTime: eventDetails.start,
    endTime: eventDetails.end,
  });

  useEffect(() => {
    const fetchAttendees = async () => {
      if (user) {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const attendeesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAttendees(attendeesList);
      }
    };

    const fetchSubjects = async () => {
      if (user) {
        const querySnapshot = await getDocs(collection(db, 'subjects'));
        const subjectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSubjects(subjectsList);
      }
    };

    fetchAttendees();
    fetchSubjects();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData(prevState => ({
      ...prevState,
      attendee: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const eventDoc = {
      title: formData.title,
      startTime: formData.startTime,
      endTime: formData.endTime,
      creator: user.uid,
      note: formData.note,
      subject: formData.subject,
      attendees: [...formData.attendee, user.uid]
    };

    try {
      await addDoc(collection(db, 'events'), eventDoc);
      onSave(eventDoc);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="pulsed-event-modal" onClick={onClose}>
      <div className="pulsed-event-content" onClick={(e) => e.stopPropagation()}>
        <h2>Create/Edit Event</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Note"
            name="note"
            value={formData.note}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            label="Start Time"
            name="startTime"
            type="datetime-local"
            value={formData.startTime}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Time"
            name="endTime"
            type="datetime-local"
            value={formData.endTime}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="attendee-select-label">Attendees</InputLabel>
            <Select
              labelId="attendee-select-label"
              name="attendee"
              multiple
              value={formData.attendee}
              onChange={handleSelectChange}
              input={<OutlinedInput label="Attendees" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {attendees.map(attendee => (
                <MenuItem key={attendee.id} value={attendee.id}>
                  <Checkbox checked={formData.attendee.indexOf(attendee.id) > -1} />
                  <ListItemText primary={attendee.username} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="subject-select-label">Subject</InputLabel>
            <Select
              labelId="subject-select-label"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            >
              {subjects.map(subject => (
                <MenuItem key={subject.id} value={subject.id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="pulsed-event-actions">
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PulsedEvent;
