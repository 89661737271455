// src/components/ConsentBanner.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

const ConsentBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem('consentGiven');
        if (!consentGiven) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('consentGiven', 'true');
        setShowBanner(false);
    };

    return (
        showBanner && (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    p: 2,
                    bgcolor: 'background.paper',
                    borderTop: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="body2">
                    We use cookies to ensure you get the best experience on our website.{' '}
                    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                        Learn more
                    </a>
                </Typography>
                <Button variant="contained" color="primary" onClick={handleAccept}>
                    Accept
                </Button>
            </Box>
        )
    );
};

export default ConsentBanner;
