// AuthForm.js
import React, { useState, useRef } from 'react';
import { auth, db, oidcProvider } from '../config/firebaseConfig';
import {
    sendEmailVerification, signInWithEmailAndPassword, createUserWithEmailAndPassword, setPersistence,
    browserLocalPersistence, browserSessionPersistence, sendPasswordResetEmail, signInWithPopup
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MuiAlert from "@mui/material/Alert";
import {
    Avatar, Button, Box, CssBaseline, FormControlLabel, Snackbar, TextField,
    Checkbox, Link, Grid
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import Copyright from './Copyright';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import './auth.css';

const AuthForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showSignUp, setShowSignUp] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info', // can be "error", "warning", "info", "success"
    });
    const navigate = useNavigate();

    const handleSignIn = async () => {
        try {
            const persistenceType = rememberMe ? browserLocalPersistence : browserSessionPersistence;
            await setPersistence(auth, persistenceType);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            if (userCredential) {
                // Check if the email has been verified
                if (userCredential.user.emailVerified) {
                    // Email is verified, proceed with the sign-in process
                    const uid = userCredential.user.uid;
                    const userDoc = await getDoc(doc(db, "users", uid));
                    if (!userDoc.exists()) {
                        throw new Error('User not found in Firestore');
                    }
                    const role = userDoc.data().role;
                    navigate('/dashboard');
                } else {
                    // Email is NOT verified, show snackbar message and sign out the user
                    await auth.signOut(); // Sign out the user
                    showSnackbar("Please verify your email before signing in.", "warning");
                    return; // Stop the function here to prevent further actions
                }
            }
        } catch (error) {
            showSnackbar(error.message, "error");
        }
    };

    // Make sure to import necessary Firebase methods and any utility you use for avatars or UUIDs
    const handleSignUp = async () => {
        try {
            if (!validateEmail(email) || !isValidPassword(password) || !termsAccepted) {
                showSnackbar("Invalid email, password or terms not accepted", "error");
                return;
            }
            const persistenceType = rememberMe ? browserLocalPersistence : browserSessionPersistence;
            await setPersistence(auth, persistenceType);
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            // Await the email verification sending
            await sendEmailVerification(user, {
                url: process.env.REACT_APP_LOGIN_URL, // Ensure this environment variable is set
            });

            showSnackbar("Verification email sent. Please check your inbox.", "success");
            setShowSignUp(false); // Assuming this updates state to control the display of the sign-up form
        } catch (error) {
            showSnackbar(error.message, "error");
        }
    };

    const handleResetPassword = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        if (!email) {
            showSnackbar("Please enter your email address.", "warning");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            // Successfully sent password reset email
            showSnackbar("We have sent a link to reset your password to your email address.", "info");
        } catch (error) {
            // Firebase will handle errors, such as email not found, but you may still inform the user
            // that a reset email has been sent to avoid disclosing whether an email is registered.
            showSnackbar("We have sent a link to reset your password to your email address.", "info");
        }
    };

    const handleOIDCLogin = async () => {
        try {
            const result = await signInWithPopup(auth, oidcProvider);
            if (result.user) {
                navigate('/dashboard');
            }
        } catch (error) {
            showSnackbar(error.message, "error");
        }
    };

    // Add a ref for the reCAPTCHA
    const recaptchaRef = useRef(null);

    const onReCAPTCHAChange = (captchaCode) => {
        if (!captchaCode) {
            // If captcha is expired or closed, captchaCode will be null. Handle accordingly.
            return;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        recaptchaRef.current.execute();
        if (showSignUp) {
            await handleSignUp();
        } else {
            await handleSignIn();
        }
    }

    const showSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSignUpClick = () => {
        setShowSignUp(!showSignUp);
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    }

    const handleTermsAcceptedChange = () => {
        setTermsAccepted(!termsAccepted);
    }

    const handleEmailOnChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsValidEmail(validateEmail(inputEmail));
        if (!isValidEmail) {
            showSnackbar("Email is not in a valid format.", "error");
        } else if (inputEmail !== '' && snackbar.open) {
            handleCloseSnackbar();
        }
    }

    const validateEmail = (email) => {
        // Simple email validation pattern
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const isValidPassword = (password) => {
        // Password must be at least 6 characters long
        return password.length >= 6;
    };

    return (
        <ThemeProvider theme={theme}>
            <Container className="auth-form" component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: '#262A5E' }}>
                        {!showSignUp ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ color: 'primary.main' }}>
                        {!showSignUp ? "Sign in" : "Sign up"}
                    </Typography>
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            severity={snackbar.severity}
                            onClose={handleCloseSnackbar}
                        >
                            {snackbar.message}
                        </MuiAlert>
                    </Snackbar>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleEmailOnChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        {!showSignUp && <FormControlLabel
                            control={<Checkbox value="remember" color="primary" checked={rememberMe} onChange={handleRememberMeChange} />}
                            label={<Typography sx={{ color: 'primary.main' }}>Remember me</Typography>}
                        />}

                        {showSignUp && (
                            <FormControlLabel
                                control={<Checkbox value="termsAccepted" color="primary" checked={termsAccepted} onChange={handleTermsAcceptedChange} />}
                                label={
                                    <Typography sx={{ color: 'primary.main' }}>
                                        I agree to the <Link href="/privacy-policy" target="_blank" rel="noopener">Privacy Policy</Link> and <Link href="/terms-and-conditions" target="_blank" rel="noopener">Terms and Conditions</Link>
                                    </Typography>
                                }
                            />
                        )}

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {!showSignUp ? "Sign In" : "Sign Up"}
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 2, mb: 2 }}
                            onClick={handleOIDCLogin}
                        >
                            Sign in with Modus-medizin
                        </Button>
                        <Grid container className="auth-links" >
                            {!showSignUp ? (
                                <Link href="#" variant="body2" onClick={() => handleSignUpClick(true)}>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            ) : (
                                <Link href="#" variant="body2" onClick={() => handleSignUpClick(false)}>
                                    {"Already have an account? Sign In"}
                                </Link>
                            )}
                        </Grid>
                        <Grid container className="auth-links">
                            <Link href="#" variant="body2" onClick={(e) => handleResetPassword(e)}>
                                Forgot password?
                            </Link>
                        </Grid>
                    </Box>
                </Box>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size="invisible"
                    onChange={onReCAPTCHAChange}
                />
                <Copyright sx={{ mt: 0, mb: 0 }} />
            </Container>
        </ThemeProvider >
    );
};

export default AuthForm;
