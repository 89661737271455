import React, { useEffect, useState } from 'react';
import './PrivacyPolicy.css';

// Function to fetch the HTML content from a given URL
const fetchHTMLContent = async (url) => {
  const response = await fetch(url);
  return response.text();
};

const PrivacyPolicy = () => {
  const [content1, setContent1] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');

  useEffect(() => {
    const loadContent = async () => {
      const content1 = await fetchHTMLContent('/html/PrivacyPolicyTermly1.html');
      const content2 = await fetchHTMLContent('/html/PrivacyPolicyTermly2.html');
      const content3 = await fetchHTMLContent('/html/PrivacyPolicyTermly3.html');
      setContent1(content1);
      setContent2(content2);
      setContent3(content3);
    };

    loadContent();
  }, []);

  return (
    <div className="policy-container">
      <div className="privacy-policy-content" dangerouslySetInnerHTML={{ __html: content1 }} />
      <div className="privacy-policy-content" dangerouslySetInnerHTML={{ __html: content2 }} />
      <div className="privacy-policy-content" dangerouslySetInnerHTML={{ __html: content3 }} />
    </div>
  );
};

export default PrivacyPolicy;
