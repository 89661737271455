// StudyPlanCard.js
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import dayjs from 'dayjs';
import './StudyPlanCard.css';

const StudyPlanCard = ({ plan, setShowForm }) => {
    return (
        <Card className="study-plan-card" onClick={() => setShowForm(true)}>
            <CardContent className="study-plan-card-content">
                <Typography variant="h5" gutterBottom>{plan.title}</Typography>
                <Typography color="textSecondary">Exam: {plan.exam ? plan.exam.name : 'Unknown'}</Typography>
                <Typography color="textSecondary">Start Date: {dayjs(plan.startDate).format('YYYY-MM-DD')}</Typography>
                <Typography color="textSecondary">End Date: {dayjs(plan.endDate).format('YYYY-MM-DD')}</Typography>
                {plan.topics.map((topic) => (
                    <React.Fragment key={topic.id}>
                        <Typography color="textSecondary">Topic: {topic.name}</Typography>
                        {topic.chapters.map((chapter) => (
                            <Typography key={chapter.id} color="textSecondary">Chapter: {chapter.name}</Typography>
                        ))}
                        ----
                    </React.Fragment>
                ))}
            </CardContent>
        </Card>
    );
};

export default StudyPlanCard;
