// StudyPlanForm.js
import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebaseConfig';
import { collection, addDoc, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import {
    TextField, MenuItem, FormControl, InputLabel, Select, Chip, OutlinedInput, Box,
    Checkbox, ListItemText, List, ListItem, IconButton, Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const StudyPlanForm = ({ user, setShowForm, setStudyPlans }) => {
    const [title, setTitle] = useState('');
    const [exam, setExam] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [weekDays, setWeekDays] = useState([]);
    const [daysOff, setDaysOff] = useState([]);
    const [exams, setExams] = useState([]);

    const weekDayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    useEffect(() => {
        const fetchExams = async () => {
            const querySnapshot = await getDocs(collection(db, "exams"));
            const examsData = querySnapshot.docs.map(doc => ({
                uid: doc.id,
                ...doc.data()
            }));
            setExams(examsData);
        };

        fetchExams();
    }, []);

    const handleAddDayOff = (newDate) => {
        if (newDate && !daysOff.some(date => date.isSame(newDate, 'day'))) {
            setDaysOff(prevDaysOff => [...prevDaysOff, newDate]);
        }
    };

    const handleRemoveDayOff = (dateToRemove) => {
        setDaysOff(prevDaysOff => prevDaysOff.filter(date => !date.isSame(dateToRemove, 'day')));
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            const newStudyPlan = {
                userId: user.uid,
                title,
                exam,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                weekDays,
                daysOff: daysOff.map(d => d.toISOString())
            };

            const studyPlansCollection = collection(db, "studyPlans");
            const docRef = await addDoc(studyPlansCollection, newStudyPlan);

            setStudyPlans(prevPlans => [...prevPlans, newStudyPlan]);

            const studyPlanId = docRef.id;

            const userDocRef = doc(db, "users", user.uid);
            await updateDoc(userDocRef, {
                studyPlans: arrayUnion(studyPlanId)
            });

            setShowForm(false);
        } catch (error) {
            console.error("Error submitting study plan: ", error);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={handleSubmit}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    required
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="exam-select-label">Exam</InputLabel>
                    <Select
                        labelId="exam-select-label"
                        value={exam}
                        label="Exam"
                        onChange={e => setExam(e.target.value)}
                        required
                    >
                        {exams.map((exam) => (
                            <MenuItem key={exam.uid} value={exam.uid}>{exam.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                        setStartDate(newValue);
                    }}
                    components={{
                        TextField: (params) => <TextField {...params} />
                    }}
                />
                <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                        setEndDate(newValue);
                    }}
                    components={{
                        TextField: (params) => <TextField {...params} />
                    }}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="weekdays-multiple-chip-label">Weekdays</InputLabel>
                    <Select
                        labelId="weekdays-multiple-chip-label"
                        multiple
                        value={weekDays}
                        onChange={e => setWeekDays(e.target.value)}
                        input={<OutlinedInput id="select-multiple-chip" label="Weekdays" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                    >
                        {weekDayOptions.map((day) => (
                            <MenuItem key={day} value={day}>
                                <Checkbox checked={weekDays.indexOf(day) > -1} />
                                <ListItemText primary={day} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <DatePicker
                        label="Add Day Off"
                        value={null}
                        onChange={handleAddDayOff}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <List>
                        {daysOff.map((date, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={date.format('YYYY-MM-DD')} />
                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveDayOff(date)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </FormControl>
                <Button type="submit" variant="contained" color="primary">Submit Study Plan</Button>
            </form>
        </LocalizationProvider>
    );
};

export default StudyPlanForm;
