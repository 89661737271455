import React, { useState, useEffect } from 'react';
import { auth, db, storage } from '../../config/firebaseConfig';
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, list, deleteObject } from "firebase/storage";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';
import FileListModal from './FileListModal';
import './Content.css';

const ContentTree = ({ userData }) => {
    const [subjects, setSubjects] = useState([]);
    const [open, setOpen] = useState({});
    const [fileList, setFileList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const userRoles = userData.roles || [];

    const handleClick = (id) => {
        setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
    };

    const handleUpload = async (event, nodeId) => {
        const file = event.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `attachments/${nodeId}/${file.name}`);
        try {
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            console.log(`File uploaded successfully: ${downloadURL}`);
            // Optionally update Firestore database with file reference
        } catch (error) {
            console.error("Upload failed", error);
        }
    };

    const listFiles = async (nodeId) => {
        const listRef = ref(storage, `attachments/${nodeId}`);
        try {
            const res = await list(listRef);
            const files = await Promise.all(res.items.map(async (itemRef) => {
                const url = await getDownloadURL(itemRef);
                return { name: itemRef.name, url, filePath: itemRef.fullPath };
            }));
            setFileList(files);
            setShowModal(true);
        } catch (error) {
            console.error("Failed to list files: ", error);
        }
    };

    const handleDownload = (url) => {
        window.open(url, '_blank');
    };

    const handleDelete = async (filePath) => {
        const fileRef = ref(storage, filePath);
        try {
            await deleteObject(fileRef);
            console.log(`${filePath} deleted successfully.`);
            setFileList((prevFiles) => prevFiles.filter((file) => file.filePath !== filePath));
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    useEffect(() => {
        let isMounted = true; // flag to track if the component is mounted

        const fetchSubjects = async () => {
            try {
                const subjectsQuerySnapshot = await getDocs(collection(db, "subjects"));
                const subjectsData = [];

                for (const subjectDoc of subjectsQuerySnapshot.docs) {
                    const subject = { id: subjectDoc.id, ...subjectDoc.data(), topics: [] };

                    const topicsQuerySnapshot = await getDocs(query(collection(db, "topics"), where("subjectId", "==", subject.id)));
                    const topicsData = await Promise.all(topicsQuerySnapshot.docs.map(async (topicDoc) => {
                        const topic = { id: topicDoc.id, ...topicDoc.data(), chapters: [] };

                        const chaptersQuerySnapshot = await getDocs(query(collection(db, "chapters"), where("topicId", "==", topic.id)));
                        topic.chapters = chaptersQuerySnapshot.docs.map(chapterDoc => ({
                            id: chapterDoc.id,
                            ...chapterDoc.data()
                        }));

                        return topic;
                    }));

                    subject.topics = topicsData;
                    subjectsData.push(subject);
                }

                if (isMounted) {
                    setSubjects(subjectsData);
                }
            } catch (error) {
                console.error("Failed to fetch subjects:", error);
            }
        };

        fetchSubjects();

        return () => {
            isMounted = false; // clean up flag to prevent state updates after unmount
        };
    }, []);

    const renderTree = (nodes, level = 0) => nodes.map((node) => (
        <React.Fragment key={node.id}>
            <ListItemButton onClick={() => handleClick(node.id)} style={{ paddingLeft: `${20 + level * 20}px` }} className="list-item-button">
                <ListItemText primary={node.name} className="min-content-list-item" />
                {open[node.id] ? <ExpandLess /> : <ExpandMore />}
                <ListItemSecondaryAction>
                    {userRoles.includes("teacher") && (
                        <>
                            <input
                                accept="*/*"
                                style={{ display: 'none' }}
                                id={`file-upload-${node.id}`}
                                type="file"
                                onChange={(event) => handleUpload(event, node.id)}
                            />
                            <label htmlFor={`file-upload-${node.id}`}>
                                <IconButton component="span">
                                    <AttachFileIcon />
                                </IconButton>
                            </label>
                        </>
                    )}
                    <IconButton edge="end" aria-label="download" onClick={() => listFiles(node.id)}>
                        <DownloadIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse in={open[node.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {node.topics && renderTree(node.topics, level + 1)}
                    {node.chapters && renderTree(node.chapters, level + 2)}
                </List>
            </Collapse>
        </React.Fragment>
    ));

    return (
        <>
            <List>
                {renderTree(subjects)}
            </List>
            <FileListModal
                open={showModal}
                handleClose={() => setShowModal(false)}
                handleDelete={userRoles.includes("teacher") ? handleDelete : null}
                files={fileList}
                handleDownload={handleDownload}
            />
        </>
    );
};

export default ContentTree;